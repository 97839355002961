import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Avatar, Button, CardMedia } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import MeditationPrompt from '../../components/feed/MeditationPrompt';
import useDeviceInfo from '../../utils/useDeviceInfo';
import ZenSpinner from '../../components/ZenSpinner'; // Import du composant ZenSpinner

const RoomList = () => {
  const { currentUser } = useAuth();
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true); // État de chargement
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const handleStartClick = () => {
    navigate('/temple');
  };

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
  
          // Trier les rooms par la date de la dernière méditation (les plus récentes en premier)
          const sortedRooms = data.rooms.sort((a, b) => {
            const dateA = new Date(getLastMeditationDate(a)).getTime();
            const dateB = new Date(getLastMeditationDate(b)).getTime();
            return dateB - dateA;
          });
  
          setRooms(sortedRooms);
        } else {
          console.error('Erreur lors de la récupération des salles.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des salles:', error);
      } finally {
        setLoading(false); // Fin du chargement
      }
    };
  
    if (currentUser) {
      fetchRooms();
    }
  }, [currentUser]);

  const handleJoinRoom = (roomCode) => {
    navigate(`/room/${roomCode}`);
  };

  const getFriendDetails = (userId) => {
    return currentUser.friends.find(friend => friend._id === userId);
  };

  const getRoomDisplayName = (room) => {
    if (room.groupName) {
      return room.groupName;
    }

    const otherUserId = room.userIds.find(userId => userId !== currentUser.userId);
    const otherUser = getFriendDetails(otherUserId);

    return otherUser ? `${otherUser.firstName || 'Nom inconnu'} ${otherUser.lastName || ''}` : 'Utilisateur inconnu';
  };

  const getRoomAvatar = (room) => {
    if (room.groupAvatar) {
      return room.groupAvatar;
    }

    const otherUserId = room.userIds.find(userId => userId !== currentUser.userId);
    const otherUser = getFriendDetails(otherUserId);

    return otherUser ? otherUser.avatar : '/default-avatar.png';
  };

  const formatLastMeditationDate = (date) => {
    const today = new Date();
    const meditationDate = new Date(date);

    today.setHours(0, 0, 0, 0);
    meditationDate.setHours(0, 0, 0, 0);

    const diffTime = today - meditationDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return "En ligne aujourd'hui";
    } else if (diffDays === 1) {
      return "En ligne hier";
    } else {
      return `En ligne il y a ${diffDays} jours`;
    }
  };

  const getLastMeditationDate = (room) => {
    if (room.userIds.length === 2) {
      const otherUserId = room.userIds.find(userId => userId !== currentUser.userId);
      const otherUser = getFriendDetails(otherUserId);
      return otherUser && otherUser.lastMeditationDate
        ? formatLastMeditationDate(otherUser.lastMeditationDate)
        : "N'a pas encore médité";
    } else {
      let lastMeditationDate = null;

      room.userIds.forEach(userId => {
        const user = getFriendDetails(userId);
        if (user && user.lastMeditationDate) {
          const userDate = new Date(user.lastMeditationDate);
          if (!lastMeditationDate || userDate > lastMeditationDate) {
            lastMeditationDate = userDate;
          }
        }
      });

      return lastMeditationDate
        ? formatLastMeditationDate(lastMeditationDate)
        : 'Aucune méditation récente';
    }
  };

  const handleCTAClick = () => {
    navigate('/today', { state: { openFriendsDrawer: true } });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '84vh',
        }}
      >
        <ZenSpinner /> {/* Afficher le spinner de préchargement */}
      </Box>
    );
  }

  if (rooms.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '72vh',
          textAlign: 'center',
          p: 0,
        }}
      >
  
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Box sx={{ pt: 3, pb:2, height: '20vh', overflow: 'hidden', width:'50%', position: 'relative', backgroundColor: 'secondary.main', borderRadius:2 }}>
              <Box className="scrolling-image" sx={{ display: 'flex', height: '100%' }}>
                  <CardMedia component="img" image="/img/allfriends.svg" alt="Méditer avec des amis" sx={{ height: '100%', display: 'block' }} />
              </Box>
          </Box>

          <Box sx={{ width:'100%' }} >
            <Typography sx={{ mt:1.5, px: 3.5 }}  variant='h5'>
              Ajoute des amis pour
            </Typography>
            <Typography sx={{ mt:0, mb:1.5, px: 3.5 }}  variant='h5'>
               méditer avec eux
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCTAClick}
              sx={{ mb:3.2, width:'50%' }}
            >
              C'est parti
            </Button>
          </Box>

        </Box>

      </Box>
    );
  }

  return (
    <Box sx={{ mb: 3 }}>

      <MeditationPrompt onStartMeditation={handleStartClick} />

      <Box sx={{ px:0, pt:2, pb: paddingBottom }}>
        <Typography variant="h6" sx={{ mb: 1}} gutterBottom>Méditer avec des amis</Typography>
        <List sx={{ p: 0 }}>
          {rooms.map((room, index) => (
            <ListItem sx={{ p: 0, mt:0.5 }} key={index} onClick={() => handleJoinRoom(room._id)}>
              <Avatar
                alt={getRoomDisplayName(room)}
                src={getRoomAvatar(room)}
                sx={{ mr: 2, border: '1px solid #ddd', width: 52, height: 52 }} // Augmentation de la taille de l'avatar
              />
              <ListItemText
                primary={getRoomDisplayName(room)}
                secondary={getLastMeditationDate(room)}
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: 'bold', 
                  },
                  '& .MuiListItemText-secondary': {
                    fontWeight: 'normal',
                    fontSize:'0.8em',
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default RoomList;
